<div fxFill>

    <div fxFill fxLayout="column" fxLayoutAlign="space-between stretch" fxFlexAlign="stretch">
        <!-- Router -->
        <div>
            <router-outlet></router-outlet>
        </div>
        <!---->
        <!-- App Footer  -->
        <!-- <div>
            <app-footer></app-footer>
        </div> -->
        <!---->
    </div>
</div>

import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-login-header',
  templateUrl: './login-header.component.html',
  styleUrls: ['./login-header.component.scss']
})
export class LoginHeaderComponent implements OnInit {

  isUser: boolean = false;

  constructor(
    private auth: AuthService
  ) { }

  onLogout() {
    this.auth.onLogout()
  }

  ngOnInit(): void {
    this.auth.currentUser.subscribe(res => {
      console.log(res)
      if (res && res !== null) {
        this.isUser = true;
      } else {
        this.isUser = false;
      }
    })
  }

}

<!-- Side Menu -->
<div class="side-nav">
  <!-- <div class="logo">
    <a routerLink="">
      <img src="assets/images/logo.png">
    </a>
  </div> -->


  <ul fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="25px">

    <li>
      <a routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" routerLink="">
        Home
      </a>
    </li>

    <li>
      <a routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" routerLink="/dataset">
        Dataset
      </a>
    </li>


    <li>
      <a routerLinkActive="active" routerLink="/eligibility">
        Eligibility
      </a>
    </li>

    <li>
      <a routerLinkActive="active" routerLink="/scoring">
        Scoring
      </a>
    </li>



    <li>
      <a routerLinkActive="active" routerLink="/limit">
        Limit
      </a>
    </li>


    <li>
      <a routerLinkActive="active" routerLink="/interest">
        Interest
      </a>
    </li>

    <li>
      <a routerLinkActive="active" routerLink="/segment">
        Segment
      </a>
    </li>

    <li>
      <a routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" routerLink="/reports">
        Report
      </a>
    </li>


    <!-- <li (click)="subMenu1 = !subMenu1" fxLayoutGap="10px" fxLaytou="row" fxLayoutAlign="start center">
      <mat-icon>
        timeline
      </mat-icon>
      <div fxFlex="100%" fxLaytou="row" fxLayoutAlign="space-between center">
        <a>Dashboard</a>
        <span class="material-icons">
          {{subMenu1? 'keyboard_arrow_down' : 'keyboard_arrow_right'}}
        </span>
      </div>
    </li>
    <div class="sub" *ngIf="subMenu1">
      <li>
        <a routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
          - Engagement Dashboard
        </a>
      </li>
      <li>
        <a routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" routerLink="/loan-dashboard">
          - Loan Dashboard
        </a>
      </li>
      <li>
        <a routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" routerLink="/leads-dashboard">
          - Leads Dashboard
        </a>
      </li>
    </div> -->



    <!-- <li (click)="subMenu2 = !subMenu2" fxLayoutGap="10px" fxLaytou="row" fxLayoutAlign="start center">
      <mat-icon>
        assignment
      </mat-icon>
      <div fxFlex="100%" fxLaytou="row" fxLayoutAlign="space-between center">
        <a>Engagement Reports</a>
        <span class="material-icons">
          {{subMenu1? 'keyboard_arrow_down' : 'keyboard_arrow_right'}}
        </span>
      </div>
    </li>
    <div class="sub" *ngIf="subMenu2">
      <li>
        <a routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" routerLink="/reports">
          - FD Reports
        </a>
      </li>
      <li>
        <a routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" routerLink="/custome-reports">
          - Customer Reports
        </a>
      </li>
    </div> -->

    <!-- <li (click)="subMenu3 = !subMenu3" fxLayoutGap="10px" fxLaytou="row" fxLayoutAlign="start center">
      <mat-icon>
        assignment
      </mat-icon>
      <div fxFlex="100%" fxLaytou="row" fxLayoutAlign="space-between center">
        <a>Loan Reports</a>
        <span class="material-icons">
          {{subMenu1? 'keyboard_arrow_down' : 'keyboard_arrow_right'}}
        </span>
      </div>
    </li>
    <div class="sub" *ngIf="subMenu3">
      <li>
        <a routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
          routerLink="/loan-report/elegibility-report">
          - Elegibility Report
        </a>
      </li>
    </div> -->

    <!-- Social Banking -->
    <!-- <li (click)="subMenu4 = !subMenu4" fxLayoutGap="10px" fxLaytou="row" fxLayoutAlign="start center">
      <mat-icon>
        assignment
      </mat-icon>
      <div fxFlex="100%" fxLaytou="row" fxLayoutAlign="space-between center">
        <a>Social Banking Reports</a>
        <span class="material-icons">
          {{subMenu1? 'keyboard_arrow_down' : 'keyboard_arrow_right'}}
        </span>
      </div>
    </li>
    <div class="sub" *ngIf="subMenu4">
      <li>
        <a routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" routerLink="/self-onboarding">
          - Self Onboarding
        </a>
      </li>
      <li>
        <a routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" routerLink="/custome-lead">
          - Customer Lead
        </a>
      </li>
      <li>
        <a routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" routerLink="/kyc-updates">
          - KYC Update
        </a>
      </li>
      <li>
        <a routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" routerLink="/debit-card">
          - Debit Card
        </a>
      </li>
      <li>
        <a routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" routerLink="/fixed-deposit">
          - Fixed Deposit
        </a>
      </li>
      <li>
        <a routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" routerLink="/service-request">
          - Service Request
        </a>
      </li>
    </div> -->
    <!---->

    <!-- <li fxLayoutGap="10px" fxLaytou="row" fxLayoutAlign="start center">
      <mat-icon>
        settings
      </mat-icon>
      <a routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" routerLink="/loan-segement">Loan
        Segement</a>
    </li>

    <li fxLayoutGap="10px" fxLaytou="row" fxLayoutAlign="start center">
      <mat-icon>
        settings
      </mat-icon>
      <a routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" routerLink="/settings">Settings</a>
    </li> -->

  </ul>
</div>
<div class="main-header">
    <div>
        <div fxLayout="row" fxLayoutAlign="space-between center">

            <div class="menu-icon">
                <!-- <a (click)="onMenu()">
                    <mat-icon>
                        menu
                    </mat-icon>
                </a> -->
            </div>
            <!-- Logo -->
            <div class="logo">
                <a routerLink="/">
                    <!-- <img src="assets/images/logo2.png" alt="Loan Dashboard"> -->
                    Loan Dashboard
                </a>
            </div>
            <div fxLayout="row" fxLayoutAlign="end center">

                <div class="nav">
                    <ul fxLayoutGap="35px" fxLayout="row" fxLayoutAlign="start center">

                        <!-- <li>
                            <div class="nav-profile" fxLayout="row" fxLayoutAlign="start center">
                                <div class="nav-profile-image">
                                    <img src="assets/images/face1.jpg">
                                </div>
                                <div>
                                    <h4>Mayank Gupta</h4>
                                </div>
                            </div>
                        </li> -->


                        <li>
                            <a (click)="onLogout()">
                                Logout
                            </a>
                        </li>
                    </ul>
                </div>
                <!---->

            </div>
        </div>
    </div>
</div>
import { Component, OnInit, HostListener, Output, EventEmitter } from '@angular/core';
@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit {

  @Output() sideMenuCose: EventEmitter<boolean> = new EventEmitter();
  subMenu1 = false;
  subMenu2 = false;
  subMenu3 = false;
  subMenu4 = false;
  subMenu5 = false;
  subMenu6 = false;

  constructor() { }

  onSideMenuClose() {
    this.sideMenuCose.emit(false)
  }

  ngOnInit() {
  }

}

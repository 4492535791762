<div fxFill>
    <div fxLayout="row" fxLayoutAlign="space-between start">
        <app-side-menu *ngIf="menuToggle" class="side-menu-open" [class.side-menu-close]="!menuToggle">
        </app-side-menu>
        <div class="content-section" [class.content-close]="!menuToggle" fxFlex="100%">
            <div class="route-box">
                <app-header>
                    <span class="material-icons menu-icon" (click)="onMenuToggle()">
                        menu
                    </span>
                </app-header>
                <div class="route-section">
                    <router-outlet></router-outlet>
                </div>
            </div>
            <app-footer></app-footer>
        </div>
    </div>
</div>
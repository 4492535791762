import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SideMenuService } from '../../../services/sideMenu/side-menu.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  isUser: boolean = false;
  isMenu: boolean = true;
  user = null
  constructor(
    private auth: AuthService,
    private sideMenuSer: SideMenuService,
  ) {
    this.auth.currentUser.subscribe(res => {
      if (res !== null) {
        this.user = res['payload']
      }
    })
  }


  onLogout() {
    this.auth.onLogout()
  }

  onMenu() {
    this.isMenu = !this.isMenu;
    this.sideMenuSer.data.next(this.isMenu)
  }

  ngOnInit(): void {
    this.auth.currentUser.subscribe(res => {
      console.log(res)
      if (res && res !== null) {
        this.isUser = true;
      } else {
        this.isUser = false;
      }
    })
  }

}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SideMenuService {

  data = new BehaviorSubject(true)
  isSideMenu$ = this.data.asObservable();

  constructor() { }
}

<div class="login-header" *ngIf="!isUser">
    <div class="container">
        <div fxLayout="row" fxLayoutAlign="space-between center">
            <!-- Logo -->
            <div class="logo">
                <a routerLink="/">
                    <img src="assets/images/logo.png" alt="PayNet Bank Logo">
                </a>
            </div>
            <div fxLayoutGap="30px" fxLayout="row" fxLayoutAlign="end center">

                <div class="nav">
                    <ul fxLayout="row" fxLayoutAlign="start center">
                    </ul>
                </div>
                <!---->

            </div>
        </div>
    </div>
</div>
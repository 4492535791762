import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate, Router } from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private auth: AuthService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let isUser = false;
    this.auth.currentUser.subscribe(user => {
      if (user !== null && user['access_token']) {
        isUser = true;
      } else if (user === null) {
        isUser = false;
      }
    })
    if (isUser) {
      // Logged in so return true
      return true;
    }

    // Not logged in so redirect to login page with the return url
    this.router.navigate(['/login']);
    // this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    return false;
  }

}
